<template>
  <div>
    <div class="section_container">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <h4 class="mb-5 order-id text-lg-right">Order #{{ details.invoice_number }}</h4>
            <div class="mb-5">
              <h3>Thank you for your purchase!</h3>
            </div>

            <hr>

            <div class="row mb-3">
              <h4 class="col-lg-6">Order Summary</h4>
            </div>
            <div
              class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between item mb-3"
              v-for="(item, i) of details.items"
              :key="i"
            >
              <div class="d-flex flex-column flex-lg-row align-items-lg-center product">
                <a>{{ item.name }} x{{ item.quantity }}</a>
              </div>
              <div class="text-lg-right price">${{ parseInt(item.discount_price) ? item.discount_price : item.regular_price }}</div>
            </div>

            <hr>

            <div class="">
              <div class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between item mb-3">
                <div>Shipping</div>
                <div class="price">${{ details.payment_fee }}</div>
              </div>
              <div
                class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between item mb-3"
                v-if="details.voucher_code && details.voucher_amount"
              >
                <div>Voucher code: {{ details.voucher_code }}</div>
                <div class="price">-${{ details.voucher_amount }}</div>
              </div>
              <div class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between item mb-3">
                <div>Total</div>
                <div class="price">${{ details.payment_total }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    details: {},
  }),

  mounted() {
    this.fetchDetails();
  },

  methods: {
    async fetchDetails() {
      const { data } = await this.$api.details(this.$route.params.token);
      this.details = data;
    },
    getProductSlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
.section_container {
  padding-bottom: 83px;
  padding-top: 83px;
}

.container {
  background: #edf4f6;
  color: #414141;
  padding: 40px;

  @media only screen and (min-width: 576px) {
    padding: 80px;
  }
}

h3,
h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.order-id {
  color: #888888;
}

.item {
  color: inherit;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.price {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.product {
  @media only screen and (min-width: 992px) {
    width: 70%;
  }

  img {
    border-radius: 4px;
    margin-right: 20px;
    width: 100px;
  }

  a {
    color: inherit;
  }
}
</style>
